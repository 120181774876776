
import React from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data;// data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (

    <Layout>
      <header style={{
        backgroundColor:'#216684',
        height: 70,
      }}/>

      <section>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-8 mx-auto">
              <h1>{frontmatter.title}</h1>
              {frontmatter.subline && (<h2>{frontmatter.subline}</h2>)}
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`